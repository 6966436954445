module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134923604-5","head":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"http://ghost-pi.local:2368","contentApiKey":"60fe88853fc34f219c7f35053a"},"production":{"apiUrl":"http://ghost-pi.local:2368","contentApiKey":"60fe88853fc34f219c7f35053a"}},"siteConfig":{"siteUrl":"https://www.gorestrepeat.com","googleAnalyticsId":"UA-134923604-5","adSensePublisherId":"ca-pub-4035860715451798","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Go, Rest, Repeat","siteDescriptionMeta":"An endless cycle of adventures and reflection","shortTitle":"Go, Rest, Repeat","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":true,"severity":"info","navigation":[{"label":"China","url":"/tag/china/"},{"label":"Taiwan","url":"/tag/taiwan/"},{"label":"South Korea","url":"/tag/south-korea/"},{"label":"Japan","url":"/tag/japan/"},{"label":"Canada","url":"/tag/canada/"},{"label":"Contact Us","url":"/contact-us/"}],"layout":{"home":{"news":{"shown":false,"title":"Latest News","tagSlug":"hash-news","postCount":5},"featuredTags":{"shown":true,"title":"Featured Destinations","slugs":["seoul","beijing","xian","hong-kong","tokyo","osaka"]},"latestPosts":{"shown":true,"title":"Latest Posts"}}},"customMap":{},"customFilter":{}},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[{"path":"news"}]}},
    },{
      plugin: require('../../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
